<template>
    <div>
        <div class="detail">
            <div class="header">
                <div class="header-back" @touchend="handleBack">
                    <van-icon name="arrow-left" color="#fff" size="0.36rem" />
                </div>
                <div class="header-title">{{info.name?info.name:title}}</div>
            </div>
            <div class="detailInfo">
                <div class="infobasic">
                    <div class="infobasic-money">
                        <div class="infobasic-limitType"><span>{{toThousandFilter(info.limitRangeMax)}}</span> 元</div>
                        <div class="infobasic-limitType infobasic-loanTermMax">
                            <span class="loanTerm">{{info.loanTermMax||0}}</span>
                            {{info.termUnit=='d'?'日':info.termUnit=='M'?'月':"年"}}
                        </div>
                    </div>
                    <div class="infomessage">
                        <div class="infomessage-list">
                            <div class="infomessage-list_item">
                                <div class="infomessage-list_label">总还款金额 (元)</div>
                                <div class="infomessage-list_value">{{toThousandFilter (info.totallimitRange)}}</div>
                            </div>
                            <div class="infomessage-list_item infomessage-list_items">
                                <div class="infomessage-list_label">到账金额 (元)</div>
                                <div class="infomessage-list_value">{{toThousandFilter(info.limitRangeMax)}}</div>
                            </div>
                        </div>
                        <div class="infomessage-list">
                            <div class="infomessage-list_item">
                                <div class="infomessage-list_label">利息和服务费 (元)</div>
                                <div class="infomessage-list_value">{{toThousandFilter(info.servercost)}}
                                    ({{info.rateUnit=='y'?'年化':info.rateUnit=='M'?'月化':info.rateUnit=='d'?'日化':''}}利率{{info.loanRate}}%)
                                </div>
                            </div>
                            <div class="infomessage-list_item infomessage-list_items">
                                <div class="infomessage-list_label">
                                    {{info.termUnit=='d'?'日':info.termUnit=='M'?'月':"月"}}还款金额 (元)</div>
                                <div class="infomessage-list_value">{{toThousandFilter(info.limitRangeavage)}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="infotip">
                        合规借款，流程便捷
                    </div>
                </div>
            </div>
            <div v-if="info.name" class="detailcontent">
                <div class="quickloan label">
                    <div class="label-line"></div>
                    <div class="label-title quickloan-header">
                        <span>速贷大数据</span>（来源于分期贷款真实贷款用户）
                    </div>
                </div>
                <div class="quick">
                    <div class="quick-item">
                        <van-circle v-model="currentRate1" layer-color="#EBEBEB" color="#FF9D20" :rate="50"
                            size="1.28rem" :stroke-width="50" text="1244">
                            <div class="van-circle__text item_tip">
                                <span>{{info.applyUserNum}}</span>
                            </div>
                        </van-circle>
                        <div class="item_value">申请人数</div>
                    </div>
                    <div class="quick-item">
                        <van-circle v-model="currentRate2" layer-color="#EBEBEB" color="#FF9D20" :rate="100"
                            size="1.28rem" :stroke-width="50" text="1.0小时光速">
                            <div class="van-circle__text item_tip">
                                <span>1.0小时</span>
                                <div class="item_light">光速</div>
                            </div>
                        </van-circle>
                        <div class="item_value">放款速度</div>
                    </div>
                    <div class="quick-item">
                        <van-circle v-model="currentRate3" layer-color="#EBEBEB" color="#FF9D20" :rate="75"
                            size="1.28rem" :stroke-width="50">
                            <div class="van-circle__text item_tip">
                                <span>12.5万</span>
                                <div class="item_light">（元）</div>
                            </div>
                        </van-circle>
                        <div class="item_value">平均额度</div>
                    </div>
                    <div class="quick-item">
                        <van-circle v-model="currentRate4" layer-color="#EBEBEB" color="#FF9D20" :rate="25"
                            size="1.28rem" :stroke-width="50" text="25%">
                            <div class="van-circle__text item_tip">
                                <span>{{info.passRate+'%'}}</span>
                            </div>
                        </van-circle>
                        <div class="item_value">下款概率</div>
                    </div>
                </div>
            </div>
            <div v-if="info.name" class="detailcontent approval">
                <div class="label">
                    <div class="label-line"></div>
                    <div class="label-title quickloan-header">
                        <span>审批条件</span>
                    </div>
                </div>
                <div class="approval-list">
                    <span class="approval-list_item" v-for="(item,index) in info.approveCondition"
                        :key="index">{{item}}</span>
                </div>
            </div>
            <div v-if="info.name" class="detailcontent more">
                <div class="label">
                    <div class="label-line"></div>
                    <div class="label-title quickloan-header">
                        <span>更多信息</span>
                    </div>
                </div>
                <div class="moretext">
                    <div class="moretext-msg" v-html="info.extraInfo"></div>
                </div>
            </div>
            <div v-if="info.name" class="recommend">
                <div class="recommend-header">
                    <img src="../../assets/img/icon.png" alt="">
                    <span class="recommend-header_title">小风为您智能推荐</span>
                </div>
                <hot-list :hotList="info.recommendList"></hot-list>
            </div>
        </div>
        <div class="applybtn" @click="handleApply">
            <div>立即申请</div>
        </div>
    </div>
</template>

<script>
import { HANDLEAPI } from '@/api/api' // api
import HotList from '@/components/Index/hotlist.vue' // list列表块
export default {
  name: 'detail',
  components: { HotList },
  data () {
    return {
      title: '极风分期',
      info: {
        name: '',
        limitRangeMax: ''
      },
      currentRate1: 0,
      currentRate2: 0,
      currentRate3: 0,
      currentRate4: 0
    }
  },
  mounted () {
    if (this.$route.query.uuid) {
      this.getRead()
    }
  },
  methods: {
    // 返回上一页
    handleBack () {
      this.$router.go(-1)
    },
    /**
         * 数字过滤
         */
    toThousandFilter (num) {
      return (+num || 0)
        .toString()
        .replace(/^-?\d+/g, (m) =>
          m.replace(/(?=(?!\b)(\d{3})+$)/g, ',')
        )
    },
    // 立即申请
    handleApply () {
      this.$store.dispatch('Buryingpoint', {
        accessType: 'track',
        pageId: 'sy00002',
        pageName: '一键申请',
        elementId: this.info.uuid,
        elementName: this.info.name,
        elementProperties: 'apply'
      })
      if (
        this.info.linkUrl &&
                /(http|https):\/\/([\w.]+\/?)\S*/.test(this.info.linkUrl)
      ) {
        const u = navigator.userAgent
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isiOS) {
          if (/(https):\/\/([\w.]+\/?)\S*/.test(this.info.linkUrl)) {
            this.$router.push({
              name: 'webview',
              query: {
                linkUrl: this.info.linkUrl,
                name: this.info.name
              }
            })
          } else {
            if (window.webkit) {
              window.webkit.messageHandlers.openInNative.postMessage(
                this.info.linkUrl
              )
            }
          }
        } else {
          this.$router.push({
            name: 'webview',
            query: {
              linkUrl: this.info.linkUrl,
              name: this.info.name
            }
          })
        }
      } else {
        window.location.href = this.info.linkUrl
      }
    },
    // 读取数据
    getRead () {
      this.$store.commit('showLoading')
      HANDLEAPI('surplusesDetail', {
        uuid: this.$route.query.uuid,
        scene: '2'
      })
        .then((res) => {
          this.$store.commit('hideLoading')
          if (res.code === 0) {
            this.info = res.data
            this.info.approveCondition = JSON.parse(
              this.info.approveCondition
            )
            if (this.info.rateUnit === 'y') {
              if (this.info.termUnit === 'd') {
                this.info.servercost =
                                    Math.round(
                                      (this.info.limitRangeMax *
                                            (this.info.loanRate * 0.01)) /
                                            365
                                    ) * this.info.loanTermMax
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        this.info.loanTermMax
                )
              } else if (this.info.termUnit === 'M') {
                this.info.servercost =
                                    Math.round(
                                      (this.info.limitRangeMax *
                                            (this.info.loanRate * 0.01)) /
                                            365
                                    ) *
                                    this.info.loanTermMax *
                                    30
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        this.info.loanTermMax
                )
              } else if (this.info.termUnit === 'y') {
                this.info.servercost =
                                    Math.round(
                                      (this.info.limitRangeMax *
                                            (this.info.loanRate * 0.01)) /
                                            365
                                    ) *
                                    this.info.loanTermMax *
                                    365
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        (this.info.loanTermMax * 12)
                )
              }
            } else if (this.info.rateUnit === 'M') {
              if (this.info.termUnit === 'y') {
                this.info.servercost = Math.round(
                  ((this.info.limitRangeMax *
                                        (this.info.loanRate * 0.01)) /
                                        30) *
                                        this.info.loanTermMax *
                                        365
                )
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        (this.info.loanTermMax * 12)
                )
              } else if (this.info.termUnit === 'M') {
                this.info.servercost = Math.round(
                  ((this.info.limitRangeMax *
                                        (this.info.loanRate * 0.01)) /
                                        30) *
                                        this.info.loanTermMax *
                                        30
                )
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        this.info.loanTermMax
                )
              } else if (this.info.termUnit === 'd') {
                this.info.servercost = Math.round(
                  ((this.info.limitRangeMax *
                                        (this.info.loanRate * 0.01)) /
                                        30) *
                                        this.info.loanTermMax
                )
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        this.info.loanTermMax
                )
              }
            } else if (this.info.rateUnit === 'd') {
              if (this.info.termUnit === 'd') {
                this.info.servercost = Math.round(
                  this.info.limitRangeMax *
                                        this.info.loanRate *
                                        0.01 *
                                        this.info.loanTermMax
                )
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        this.info.loanTermMax
                )
              } else if (this.info.termUnit === 'M') {
                this.info.servercost = Math.round(
                  this.info.limitRangeMax *
                                        this.info.loanRate *
                                        0.01 *
                                        this.info.loanTermMax *
                                        30
                )
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        this.info.loanTermMax
                )
              } else if (this.info.termUnit === 'y') {
                this.info.servercost = Math.round(
                  this.info.limitRangeMax *
                                        this.info.loanRate *
                                        0.01 *
                                        this.info.loanTermMax *
                                        365
                )
                this.info.totallimitRange =
                                    this.info.servercost +
                                    this.info.limitRangeMax
                this.info.limitRangeavage = Math.round(
                  this.info.totallimitRange /
                                        (this.info.loanTermMax * 12)
                )
              }
            }
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    }
  }
}
</script>
<style lang="less" scoped>
.detail {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #f3f3f3;
    position: relative;
    .header {
        position: relative;
        top: -0.4rem;
        height: 4.58rem;
        background-image: linear-gradient(180deg, #ff4c45 0%, #ffa534 100%);
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        // align-items: center;
        .header-title {
            width: 90%;
            color: #fff;
            font-size: 0.36rem;
            text-align: center;
            padding-top: 0.72rem;
            padding-right: 10%;
        }
        .header-back {
            width: 10%;
            padding-top: 0.68rem;
        }
    }
    .detailInfo {
        position: absolute;
        top: 1.05rem;
        left: 2.7%;
        width: 94.7%;
        box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.2);
        background: #fff;
        border-radius: 0.2rem;
        .infobasic {
            margin: 0.28rem 0.4rem 0.2rem;
            .infobasic-money {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 0.01rem solid #e4e4e4;
                .infobasic-limitType {
                    width: 68%;
                    color: #000;
                    font-size: 0.3rem;
                    line-height: 0.72rem;
                    text-align: left;
                    span {
                        color: #ff4846;
                        font-size: 0.54rem;
                        font-family: "Alternate";
                    }
                    .loanTerm {
                        color: #111;
                    }
                }
                .infobasic-loanTermMax {
                    width: 32%;
                }
            }
            .infomessage {
                padding: 0.18rem 0;
                border-bottom: 0.01rem solid #e4e4e4;
                .infomessage-list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                    padding: 0.1rem 0;
                    .infomessage-list_item {
                        width: 68%;
                        line-height: 0.3rem;
                        .infomessage-list_label {
                            font-size: 0.26rem;
                            color: #888;
                            padding: 0 0 0.1rem;
                        }
                        .infomessage-list_value {
                            font-size: 0.28rem;
                            color: #444;
                        }
                    }
                    .infomessage-list_items {
                        width: 32%;
                    }
                }
            }
            .infotip {
                padding-top: 0.2rem;
                color: #888;
                font-size: 0.26rem;
                line-height: 0.3rem;
            }
        }
    }

    .detailcontent {
        background-color: #fff;
        .label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0.3rem;
            padding: 0.25rem 0;
            border-bottom: 0.01rem solid #e4e4e4;
            .label-line {
                width: 0.04rem;
                height: 0.2rem;
                background: #ff9d20;
                border-radius: 0.04rem;
            }
            .label-title {
                width: 97%;
                color: #111111;
                font-size: 0.3rem;
                text-align: left;
                line-height: 0.42rem;
                span {
                    font-size: 0.34rem;
                    font-family: "Helveticas";
                }
            }
        }
        .quickloan {
            padding-top: 0.85rem;
        }
        .quick {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0.2rem;
            padding: 0.4rem 0;
            .quick-item {
                width: 20%;
                margin: auto;
                .item_value {
                    color: #444;
                    font-size: 0.22rem;
                    line-height: 0.3rem;
                    margin-top: 0.1rem;
                }
                .item_tip {
                    color: #444;
                    font-size: 0.26rem;
                    .item_light {
                        color: #ff9d20;
                    }
                }
            }
        }
    }
    .approval {
        margin: 0.1rem 0;
        .approval-list {
            text-align: left;
            margin: 0 0.5rem;
            padding: 0.25rem 0;
            span {
                color: #444;
                font-size: 0.22rem;
                border: 0.01rem solid #ff9d20;
                border-radius: 0.08rem;
                padding: 0.12rem 0.32rem;
                margin: 0 0.08rem;
                line-height: 0.8rem;
                white-space: nowrap;
            }
            .approval-list_item {
                position: relative;
            }
            .approval-list_item:before {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 0.19rem;
                border: 0.16rem solid #ff9d20;
                border-bottom-right-radius: 0.08rem;
                border-top-color: transparent;
                border-left-color: transparent;
                color: #fff;
            }
            .approval-list_item:after {
                content: "";
                display: block;
                width: 0.12rem;
                height: 0.05rem;
                position: absolute;
                right: 0.02rem;
                top: 0.34rem;
                border: 1px solid #fff;
                border-bottom-color: transparent;
                border-left-color: transparent;
                transform: rotate(125deg);
            }
            .approval-list_item:first-child {
                margin-left: 0;
            }
        }
    }
    .more {
        .moretext {
            margin: 0 0.5rem;
            padding: 0.25rem 0;
            .moretext-msg {
                font-size: 0.22rem;
                text-align: left;
                line-height: 0.42rem;
            }
        }
    }
    .recommend {
        margin: 0.1rem 0;
        padding-bottom: 1.4rem;
        .recommend-header {
            padding: 0.2rem 0;
            border-bottom: 0.01rem solid #e4e4e4;
            background-color: #fff;
            img {
                display: inline-block;
                width: 0.3rem;
                height: 0.3rem;
            }
            .recommend-header_title {
                color: #111;
                font-size: 0.28rem;
                line-height: 0.46rem;
                display: inline-block;
                vertical-align: middle;
                padding-left: 0.1rem;
            }
        }
    }
}
.detail::-webkit-scrollbar {
    display: none;
}
.applybtn {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    text-align: center;
    z-index: 20;
    width: 100vw;
    height: 1.2rem;
    line-height: 0.88rem;
    font-size: 0.34rem;
    box-shadow: 0 0 0.05rem 0 rgba(0, 0, 0, 0.1);
    div {
        background-image: linear-gradient(270deg, #ff9d20 0%, #f79334 100%);
        color: #fff;
        border-radius: 0.44rem;
        margin: 0.16rem 0.3rem;
    }
}
</style>
